<template>
    <div class="owl-carousel owl-theme">
        <owl-item  v-for="(slide, index) in nubber_of_slides"
        :key="index + 1" :index="index + 1"></owl-item>
    </div>
</template>
<script>
import owlItem from "./owlItem";
export default {
    components: {
        owlItem
    },
	data() {
		return {
			nubber_of_slides: 7
		}
	}
}
</script>