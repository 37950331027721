<template>
    <div class="owl-nav">
        <button type="button" class="owl-prev" @click="owlPrev()">
            <span aria-label="Previous">‹</span>
        </button>
        <button type="button" class="owl-next" @click="owlNext()">
            <span aria-label="Next">›</span>
        </button>
    </div>
</template>
<script>
export default {
	data() {
		return {
			current_slide: 1
		}
	},
	methods: {
		owlPrev() {
			owl.trigger('prev.owl.carousel', [300])
			this.current_slide -= 1;
		},
		owlNext() {
			owl.trigger('next.owl.carousel', [300])
			this.current_slide += 1;
		}
	},
	watch:{
		current_slide(){
			this.$emit('current_slide', this.current_slide)
		}
	}
}
</script>