<template>
    <div style="margin-top: 13em;">
        <owl-wrapper></owl-wrapper>
        <owl-navigation @current_slide="setCurrentSlide"></owl-navigation>
    </div>
</template>
<script>
import owlWrapper from "./components/site/owlWrapper";
import owlNavigation from "./components/site/owlNavigation";
export default {
    components: {
        owlWrapper,
        owlNavigation
    },
	data: {
		current_slide: 1
	},
	methods: {
		setCurrentSlide(slide_number) {
			this.current_slide = slide_number;
		}
	}
}
</script>