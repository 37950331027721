<template>
    <div class="item">
        <h4> {{ index }} </h4>
    </div>
</template>
<script>
export default {
    props: {
		index: {
			type: Number
		}
	},
	data() {
		return {}
	},
    mounted() {
		const plugin = document.createElement("script");
		plugin.setAttribute("src", "/assets/js/slider.js");
		plugin.async = true;
		document.head.appendChild(plugin);
	}
}
</script>